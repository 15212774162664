import { AccountCircle } from '@mui/icons-material'
import { Box, Button, Container, TextField } from '@mui/material'
import axios from 'axios'

import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { SetToken } from './useToken'
import dayjs from 'dayjs'

type LoginUser = {
  username: string
  password: string
}

const AUTH = process.env.REACT_APP_AUTH_URL || ''
const loginUser = async ({ username, password }: LoginUser) => {
  delete axios.defaults.headers.common['Authorization']

  return axios.post(AUTH, { username, password })
}

export interface LoginProps {
  setToken: SetToken
  token?: string
}

const Login: React.FC<LoginProps> = ({ setToken, token }: LoginProps) => {
  const [username, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true)
    e.preventDefault()
    try {
      const loginResponse = await loginUser({ username, password })
      const body = loginResponse.data
      if (loginResponse.status >= 400) {
        setIsLoading(false)
        return
      }
      const expiry = dayjs().add(1, 'hour').toDate()
      setToken({
        token: body.token,
        expiry,
      })
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setError(
          JSON.stringify(
            e.response?.data.message ||
              'Cannot Login invalid username or password'
          )
        )

        toast.error('Cannot Login invalid username or password', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    }
    setIsLoading(false)
  }

  if (token) {
    return <Redirect to="/" />
  }

  return (
    <Container>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Box
        sx={{
          display: 'flex',
          marginTop: 8,
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box component="form" onSubmit={handleFormSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            label="Username"
            required
            placeholder="hello@bukobus.com"
            value={username}
            onChange={(e) => setUserName(e.target.value)}
            fullWidth
            InputProps={{
              startAdornment: <AccountCircle />,
            }}
            error={error != null}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Password"
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={error != null}
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading || username === '' || password === ''}
            sx={{ mt: 1 }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

export default Login
