/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react'
import {
  useFieldArray,
  useForm,
  SubmitHandler,
  Controller,
} from 'react-hook-form'
import request from 'axios'
import { useHistory } from 'react-router-dom'
import { Stop } from '../interface/cargo-routestops.interface'
import { cargoFactory } from '../utils/api'
import {
  Button,
  Container,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
  Card,
  Typography,
} from '@mui/material'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import NumberFormat from 'react-number-format'

export type CargoItemsFormProps = {
  // Cant be bothered to find out the type
  control: any
  register: any
}

export interface CargoItems {
  quantity: number
  width: number
  weight: number
  length: number
  height: number
  value: number
}

export type Inputs = {
  point1: string
  point2: string
  cargoItems: CargoItems[]
}

export const Dashboard = () => {
  const { control, register, handleSubmit, watch } = useForm<Inputs>()
  const [point1Select, setPoint1Select] = useState<Stop[]>([])
  const [point2Select, setPoint2Select] = useState<Stop[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [price, setPrice] = useState(0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | null>(null)
  const history = useHistory()

  const fetchPoint = useCallback(async () => {
    setIsLoading(true)
    try {
      const a = cargoFactory()
      const [point1, point2] = await Promise.all([
        a.cargoPoint('pickup'),
        a.cargoPoint('dropoff'),
      ])
      if (point1.status >= 400 || point2.status >= 400) {
        history.push('/logout')
      }

      const newPoint1: Stop[] = []
      const newPoint2: Stop[] = []

      for (const obj of point1.data) {
        obj.stops.forEach((stop) => {
          newPoint1.push(stop)
        })
      }
      setPoint1Select(newPoint1)

      for (const obj of point2.data) {
        obj.stops.forEach((stop) => {
          newPoint2.push(stop)
        })
      }
      setPoint2Select(newPoint2)
    } catch {
      history.push('/logout')
    }
    setIsLoading(false)
  }, [])

  useEffect(() => {
    const s = watch(() => {
      handleSubmit(onSubmit)()
    })
    return () => s.unsubscribe()
  }, [watch])

  useEffect(() => {
    fetchPoint()
  }, [fetchPoint])

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setIsLoading(true)
    if (!data.point1 || !data.point2) {
      setPrice(0)
      return
    }
    if (data.point1 === data.point2) {
      setPrice(0)
      return
    }

    const cf = cargoFactory()
    let ci: CargoItems[] = []
    if (data.cargoItems) {
      ci = data.cargoItems.map((obj) => {
        return {
          quantity: +obj.quantity,
          width: +obj.weight,
          weight: +obj.weight,
          length: +obj.length,
          height: +obj.height,
          value: +obj.value,
        }
      })
    }
    try {
      const cargoPriceResponse = await cf.cargoPrice({
        point1: +data.point1,
        point2: +data.point2,
        cargoItems: ci,
      })
      setPrice(() => cargoPriceResponse.data.grandTotal)
    } catch (e: any) {
      console.log(e)
      console.log(e.response?.data?.msg[0], 'ee')
      if (request.isAxiosError(e) && e.response) {
        if (e.response.status === 400) {
          setError(JSON.stringify(e.response.data?.msg[0]))
          toast.error(`${e.response.data?.msg[0]}`, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }

        if (e.response.status === 401) {
          history.push('/logout')
        }
      }
      setPrice(0)
    }
    setIsLoading(false)
  }

  return (
    <Container>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Box
        sx={{
          maxWidth: 600,
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            mt: 2,
          }}
        >
          <Typography variant="h2" gutterBottom component="div">
            Price:&nbsp;
            <NumberFormat
              value={price.toString() || '0'}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'₱'}
            />
          </Typography>
          {point1Select.length > 0 && (
            <Controller
              control={control}
              name="point1"
              defaultValue={`${point1Select[0].id}`}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ width: '100%' }}>
                  <InputLabel id="point1-select-label">Point 1</InputLabel>
                  <Select
                    labelId="point1-select-label"
                    value={value}
                    label="Point 1"
                    onChange={onChange}
                    fullWidth
                  >
                    {point1Select.map((value) => (
                      <MenuItem value={value.id} key={value.id}>
                        {value.area} - {value.Category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              )}
            />
          )}
          {point2Select.length > 0 && (
            <Controller
              control={control}
              name="point2"
              defaultValue={`${point2Select[0].id}`}
              render={({ field: { onChange, value } }) => {
                return (
                  <Box sx={{ width: '100%', mt: 1 }}>
                    <InputLabel id="point2-select-label">Point 2</InputLabel>
                    <Select
                      labelId="point2-select-label"
                      value={value}
                      label="Point 2"
                      onChange={onChange}
                      fullWidth
                    >
                      {point2Select.map((value) => (
                        <MenuItem value={value.id} key={value.id}>
                          {value.area} - {value.Category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                )
              }}
            />
          )}
          <br />
        </Box>
        <Box sx={{ width: '100%' }}>
          <CargoItemForm control={control} register={register} />
        </Box>
        <Box sx={{ margin: 'auto', pb: 1 }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

const CargoItemForm = ({ control }: CargoItemsFormProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'cargoItems',
  })
  useEffect(() => {
    if (fields.length >= 1) {
      return
    }
    append({
      quantity: 1,
      weight: 1,
      width: 1,
      length: 1,
      height: 1,
      value: 1,
    })
  }, [])
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box>
        {fields.map((field, index) => {
          return (
            <Card
              key={`${field.id}`}
              sx={{
                margin: 1,
                padding: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
              variant="outlined"
            >
              <Box
                sx={{
                  gap: 1,
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                <Controller
                  control={control}
                  name={`cargoItems.${index}.quantity`}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      onChange={onChange}
                      value={Number(value)}
                      label="Quantity"
                      type="number"
                      margin="normal"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`cargoItems.${index}.weight`}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      onChange={onChange}
                      value={value}
                      label="Weight"
                      type="number"
                      margin="normal"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`cargoItems.${index}.length`}
                  defaultValue={1}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      onChange={onChange}
                      value={value}
                      label="Length"
                      type="number"
                      margin="normal"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`cargoItems.${index}.width`}
                  defaultValue="1"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      onChange={onChange}
                      value={value}
                      label="Width"
                      type="number"
                      margin="normal"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`cargoItems.${index}.height`}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      onChange={onChange}
                      value={value}
                      label="Height"
                      type="number"
                      margin="normal"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`cargoItems.${index}.value`}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      onChange={onChange}
                      value={value}
                      label="Value"
                      type="number"
                      margin="normal"
                    />
                  )}
                />
              </Box>
              {fields.length === 1 ? null : (
                <Box sx={{ margin: 'auto' }}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      remove(index)
                    }}
                    fullWidth={false}
                  >
                    Remove
                  </Button>
                </Box>
              )}
            </Card>
          )
        })}
      </Box>
      <Box sx={{ marginTop: 1, marginBottom: 1 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            append({
              quantity: 1,
              weight: 1,
              width: 1,
              length: 1,
              height: 1,
              value: 1,
            })
          }
        >
          Add More Cargo
        </Button>
      </Box>
    </Box>
  )
}

export default Dashboard
