import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { SetToken } from './useToken'

export interface LoginProps {
  setToken: SetToken
}

const Logout: React.FC<LoginProps> = ({ setToken }: LoginProps) => {
  useEffect(() => {
    setToken(undefined)
  }, [])
  return <Redirect to="/" />
}

export default Logout
