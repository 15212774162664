import React from 'react'
import './App.css'
import useToken from './components/auth/useToken'
import Login from './components/auth/Login'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Dashboard from './components/Dashboard'
import NavigationBar from './components/sidebar/NavigationBar'
import Logout1 from './components/auth/Logout1'

function App() {
  const { token, saveToken: setToken, isTokenExpired } = useToken()

  if (!token) {
    return <Login setToken={setToken} token={token} />
  }

  return (
    <div className="wrapper">
      <section>
        <BrowserRouter>
          <NavigationBar></NavigationBar>
          <Switch>
            <Route
              path="/login"
              exact={true}
              component={() => <Login setToken={setToken} token={token} />}
            />
            <Route
              path="/logout"
              exact={true}
              component={() => <Logout1 setToken={setToken} />}
            />
            <Route
              path="/dashboard"
              exact={true}
              component={() => {
                if (isTokenExpired()) {
                  return <Redirect to="/logout" />
                }
                return <Dashboard />
              }}
            />
            <Route
              path="/login"
              exact={true}
              component={() => <Login setToken={setToken} token={token} />}
            />
            <Route path="/" exact={true} component={Dashboard} />
          </Switch>
        </BrowserRouter>
      </section>
    </div>
  )
}
export default App
