import axios from 'axios'
import { CargoPriceRequest } from '../interface/cargo-price-request.interface'
import { CargoRouteStops } from '../interface/cargo-routestops.interface'
export const BASE_URL =
  process.env.REACT_APP_GATEWAY_URL ||
  'https://staging-cargo-passenger-backend.aws.bukobus.com'

export const cargoFactory = () => {
  const bearer = JSON.parse(window.localStorage.getItem('token') || '{}')

  axios.defaults.headers.common['Authorization'] = `Token ${bearer.token}`

  const cargoPoint = async (
    pickupOrDropOff: 'pickup' | 'dropoff' = 'pickup'
  ) => {
    const url =
      pickupOrDropOff === 'pickup'
        ? `${BASE_URL}/cargoroutestops?categories=true&pickup=true`
        : `${BASE_URL}/cargoroutestops?categories=true&dropoff=true`

    return axios.get<CargoRouteStops[]>(url)
  }

  const cargoPrice = async (reqBody: CargoPriceRequest) => {
    return axios.post(BASE_URL + '/cargo/price', {
      ...reqBody,
      isAccompanied: false,
    })
  }

  return {
    cargoPoint,
    cargoPrice,
  }
}
