import dayjs from 'dayjs'
import jwtDecode from 'jwt-decode'
import { useEffect, useState } from 'react'

interface LoginJwtResponse {
  token: string
  expiry: Date
}

export type SetToken = (userToken?: LoginJwtResponse) => void

export default function useToken() {
  const isTokenExpired = () => {
    if (!localStorage.getItem('token')) {
      return true
    }
    try {
      const t = JSON.parse(localStorage.getItem('token') ?? '')

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (t?.token && dayjs(t?.expiry).isBefore(dayjs())) {
        localStorage.removeItem('token')
        return true
      }
      return false
    } catch (error) {
      localStorage.removeItem('token')
      return true
    }
  }

  const getToken = () => {
    const tokenString: string | null = localStorage.getItem('token')
    if (!tokenString) return
    if (isTokenExpired()) {
      return
    }
    const userToken: LoginJwtResponse = JSON.parse(tokenString)
    return userToken.token || ''
  }

  const [token, setToken] = useState<string | undefined>(getToken())
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const saveToken = (userToken?: LoginJwtResponse) => {
    if (!userToken) {
      setToken(undefined)
      localStorage.removeItem('token')
      return
    }
    localStorage.setItem('token', JSON.stringify(userToken))
    setToken(userToken.token)
  }

  useEffect(() => {
    if (token && !isTokenExpired()) {
      setIsAuthenticated(true)
      return
    }
    setIsAuthenticated(false)
  }, [token])

  return { token, saveToken, isTokenExpired, isAuthenticated }
}
