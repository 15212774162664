import { Home, Logout } from '@mui/icons-material'
import Logout1 from './auth/Logout1'
import Dashboard from './Dashboard'

export const Routes = [
  {
    path: '/',
    sideBarName: 'Home',
    component: Dashboard,
    navBarIcon: Home,
  },
  {
    path: '/logout',
    sideBarName: 'Logout',
    component: Logout1,
    navBarIcon: Logout,
  },
]
