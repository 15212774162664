import clsx from 'clsx'
import React, { useState } from 'react'
import { NavLink, useLocation, withRouter } from 'react-router-dom'
import { Routes } from '../Routes'
import {
  AppBar,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  SvgIcon,
  Toolbar,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
})

const NavigationBar: React.FC = () => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()
  const toggleDrawer =
    (open: boolean) => (e: React.KeyboardEvent | React.MouseEvent) => {
      if (e.type === 'keydown') {
        if (
          (e as React.KeyboardEvent).key === 'Tab' ||
          (e as React.KeyboardEvent).key === 'Shift'
        ) {
          return
        }
      }
      setIsOpen(open)
    }

  const activeRoute = (routeName: string) => {
    return location.pathname === routeName
  }

  return (
    <div>
      <div>
        <AppBar position="static" color="transparent">
          <Toolbar>
            <IconButton edge="start" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
      <Drawer open={isOpen} onClose={toggleDrawer(false)}>
        <div
          className={clsx(classes.list)}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <MenuList>
            {Routes.map((prop, key) => {
              return (
                <NavLink to={prop.path} key={key}>
                  <MenuItem selected={activeRoute(prop.path)}>
                    <ListItemIcon>
                      <SvgIcon component={prop.navBarIcon}></SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary={prop.sideBarName} />
                  </MenuItem>
                </NavLink>
              )
            })}
          </MenuList>
        </div>
      </Drawer>
    </div>
  )
}

export default withRouter(NavigationBar)
